import LoadingCard from "@/components/LoadingCard.vue";

export function lazyLoad(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: LoadingCard,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 0,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}

// This functions invokes  func the first time it is called
// If called again before the wait period expires, schedules the call of the given
// call after wait period expires and calls loadingAction to allow you to notify the user
// If called over and over again, the function is never jnvoked a second time
// If called with different arguments, it generates an independent timer.
// This means that calling the same function with a changing value doesn't actually work
export function debounceLoading(loadingAction, func, wait) {
  var caller = {};
  return function () {
    var context = this,
      args = arguments;
    var key = JSON.stringify(args);
    if (!(key in caller)) {
      caller[key] = {
        timeout: null,
        later: null,
        calls: 0,
      };
    }
    caller[key].later = function () {
      if (caller[key].calls > 1) {
        func.apply(context, args);
        caller[key].calls = 0;
        caller[key].timeout = setTimeout(caller[key].later, wait);
      } else {
        caller[key] = {
          timeout: null,
          later: null,
          calls: 0,
        };
      }
    };

    if (caller[key].calls == 0) {
      func.apply(context, args);
    } else if (caller[key].calls == 1) {
      loadingAction.apply(context, args);
    }
    caller[key].calls += 1;
    clearTimeout(caller[key].timeout);
    caller[key].timeout = setTimeout(caller[key].later, wait);
  };
}

export function buildQuery(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .filter((k) => params[k])
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");
}

export function getFormattedDateToLocaleUTC(date, time = true) {
  const dateUTC = new Date(date);
  const offsetHours = dateUTC.getTimezoneOffset() / 60;
  dateUTC.setHours(dateUTC.getHours() - offsetHours);

  const optionsDate = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("es-ES", optionsDate).format(
    dateUTC
  );
  const formattedTime = new Intl.DateTimeFormat("es-ES", optionsTime).format(
    dateUTC
  );
  const [day, month, year] = formattedDate.split("/");

  if (time)
    return `${day}/${month}/${year}, ${formattedTime}`;
  else
    return `${day}/${month}/${year}`;
}

function removeAccents(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function getStringSnakeCase(text) {
  let formatText = removeAccents(text);
  formatText = formatText.toLowerCase();
  formatText = formatText.replace(/[^a-z0-9.]+/g, ' ');
  formatText = formatText.replace(/-/g, ' ');

  formatText = formatText.replace(/\s+/g, '_');
  formatText = formatText.replace(/^(_)+|(_)+$/g, '');

  return formatText;
}

export function formatClientName(client) {
  let clientName = `${client.person.name} ${client.person.surname}`;
  if (client.person.alias) {
    clientName += ` (${client.person.alias})`;
  }
  return clientName;
}

export const loadAllTranslations = (i18nInstance) => {
  const locales = require.context('./locale', true, /\.json$/);

  locales.keys().forEach((key) => {
    const locale = key.match(/([A-Za-z0-9-_]+)\./i)[1];
    const messages = locales(key);
    i18nInstance.setLocaleMessage(locale, {
      ...i18nInstance.getLocaleMessage(locale),
      ...messages
    });
  });
};
